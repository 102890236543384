export class Variables{
    /* DEV */
    
    /*
    
        clientId = "849de88c-519d-4a44-8456-e6bb432f7e4f";
        redirectUri= "http://localhost:3000/";
        Url = "http://localhost:8083/";
        production = false;  
      */  
        
      
     
   /* TEST 207, 212 */
 /*
   
        clientId = "9ea9c367-433c-44a6-b7ba-65f7d01dbbfc";
        redirectUri= "https://etaupgrade-test.umusic.net";
        Url = "https://etaupgradetest-service.umusic.net/";
        production = false;
   */
    /* UAT 210 */
   
        clientId = "694afcfd-e849-4edf-b899-c91f6b69819d";        
        redirectUri= "https://eta2-uat.umusic.com"
        Url = "https://eta2-uatservice.umusic.com/"
        production = false;
    
        /* PROD 211 Internal and External */
     
     /* clientId = "e8c524b6-ed13-4da7-8034-f1ed88172461";
      redirectUri= "https://eta2.umusic.com";
      Url = "https://eta2-svc.umusic.com/";
      production = true;
      */
      

     /* PROD 211 */
     /*
        clientId = "0b2ebafa-8ccf-45cd-b12c-df2be739d82e";
        redirectUri= "https://eta2-prod-intra.umusic.com";
        Url = "https://eta2-prod-service.umusic.com/";
        production = true;
        */
      
  // ReportPath="http://aws19wvsql085.global.umusic.ext/ReportServer?/eTA/"; //--PROD
    ReportPath= "http://aws19wvsql078.global.umusic.ext/ReportServer?/eTA/" //--TEST
    UserSupportEmailAddr = "basheer.ahamed@dxc.com;anandaraman.s2@dxc.com;EnterpriseITEnterpriseSystems@BCDTravel.com";
    //CTM Configuration UAT    
   /*
    grant_type = 'client_credentials';
    client_id = 'b2103ba1-e616-4231-bcb8-4d4a2a2a53b9';
    client_secret = 'Uh8SAC5n1E0HmNihqGUTlXVasIQOFYsdJekcyjlM';
    BCD_TokenAccessPath = 'https://stage-content-services.ctmna-apps.net/api/v1/token';
*/
    //CTM Configuration Prod 
   
    grant_type = 'client_credentials';
    client_id = '968eddd3-0077-4588-8e0b-fcefcc103133';
    client_secret = 'WgTBV3aD6f0AQiY7qiMfIJXn85FK63Iqfg1SS4iS';
    BCD_TokenAccessPath = 'https://content-services.ctmna-apps.net/api/v1/token';

    
    
    /*
    BCD Configuration
    //client_id = '5910bdae66lhsb2qgjkmhosa05';
    //client_secret = '175uuosmoj0k819stc6h336p7hqojdfoonu8si7cqtt2pjat2vt5';
   // BCD_TokenAccessPath = 'https://auth.travel-data-api.bcdtravel.com/oauth2/token';
     */
   CTM_Url = 'https://stage-content-services.ctmna-apps.net/api/v1/booking/CGAJAE?clientId=b2103ba1-e616-4231-bcb8-4d4a2a2a53b9'

    GETRATES = false;

    Controller = {
        login: "login/",
        common: "common/",
        menu: "menu/",
        logout: "logout/",
        createTA: "createTA/",
        trackinggrid: "trackinggrid/",
        traveller: "traveller/",
        airRail: "airRail/", 
        hotel: "hotel/",
        rentalCar: "rentalCar/",
        carService: "carService/",
        codingSummary: "codingSummary/",
        approver: "approver/",
        bcdAgent: "bcdAgent/",
        submitter: "submitter/",
        bcdws: "bcdws/",
        email: "email/",
        restAPI: "restAPI/",
        soapSAP: "soapSAP/",
        carVoucher:"carVoucher/",
        approvalDiagram:"approvalDiagram/",
        directbill: "directbill/",
        directbillReport: "directbillReport/",
        labelMaintenance:"labelMaintenance/",
        systemMaintenance:"systemMaintenance/",
        businessUnitMaintenance:"businessUnitMaintenance/",
        carServiceMaintenance:"carServiceMaintenance/",
        departmentMaintenance:"departmentMaintenance/",
        carServiceIncomingCA:"carServiceIncomingCA/",
        bcdMaintatenanceCode:"bcdMaintatenanceCode/",
        report:"report/",
        user:"user/",
        voucherPlus:"voucherPlus/",
        approvalProcess:"approvalProcess/",
        addBCDCoding:"addBCDCoding/",
        userAuthenticate:"userAuthenticate/",
        contentWinner:"contentWinner/"
    };
    commonService = {
        selectMultipleRole: "selectMultipleRole",
        getUser: "getUser",
        getLabels: "getLabels",
        getTATraveler: "getTATraveler",
        deleteAuthReq: "deleteAuthReq",
        getTaAirCodingOK: "getTaAirCodingOK",
        getTaHotelCodingOK: "getTaHotelCodingOK",
        getTaRentalCarCodingOK: "getTaRentalCarCodingOK",
        getTaCarServiceCodingOK: "getTaCarServiceCodingOK",
        getTaCoding: "getTaCoding",
        updateTAMove: "updateTAMove",
        selectAppUserByExpType: "selectAppUserByExpType",
        selectMaxApprovers: "selectMaxApprovers",
        getTravelerInfoFormRequired: "getTravelerInfoFormRequired",
        updateTAHistory: "updateTAHistory",
        getBookingsBCD: "getBookingsBCD",
        deleteBookingBCD: "deleteBookingBCD",
        updateTA: "updateTA",
        updateTRCoding: "updateTRCoding",
        getAllCountry: "getAllCountry",
        getStateProvince: "getStateProvince",
        getSubmitter: "getSubmitter",
        deleteTraveller: "deleteTraveller",
        getApproverHistoryBCD: "getApproverHistoryBCD",
        getAirLineBCDCodes: "getAirLineBCDCodes",
        upload:"upload",
        writeFile:"writeFile",
        getAuthlockedDetails: "getAuthlockedDetails",
        getAuthlockedCount: "getAuthlockedCount",
        updateLockAuthreq: "updateLockAuthreq",
        updateUnLockAuthreq: "updateUnLockAuthreq"
    }
    loginService = {
        userExistsInApp: "userExistsInApp",
        afterSuccessfulLogin: "afterSuccessfulLogin",
        updateLogSuccessfulLogin: "updateLogSuccessfulLogin",
        getActiveUserLogin: "getActiveUserLogin"
    };
    menuService = {
        getMenuVariable: "getMenuVariable",
        getCarServiceCompany: "getCarServiceCompany",
        searchUserDirect: "searchUserDirect",
        searchUser: "searchUser"
    };
    logoutService = {
        logoutUser: "logoutUser"
    }
    createTAService = {
        getTravelAgent: "getTravelAgent",
        getAuthorizationType: "getAuthorizationType",
        createTA: "createTA",
        getCarServiceAgent: "getCarServiceAgent"
    }
    trackinggridService = {
        getTravellerSearch: "getTravellerSearch",
        getApprover: "getApprover"
    }
    travellerService = {
        getTA: "getTA",
        etaTravelerSearch: "etaTravelerSearch",
        addTravellerDetails: "addTravellerDetails",
        insertTravelerOnTheFly: "insertTravelerOnTheFly",
        getTravellerInfo: "getTravellerInfo",
        getTravellersForTA: "getTravellersForTA",
        getTravellerDetailsByRLN: "getTravellerDetailsByRLN",
        getBCDGeneratedTravel: "getBCDGeneratedTravel",
        updateTravellerDetailsBCD: "updateTravellerDetailsBCD",
        updateTravellerDetailsBCDRecLoc: "updateTravellerDetailsBCDRecLoc",
        getPNRByXML: "getPNRByXML",
        insertAirBookingsBCD: "insertAirBookingsBCD",
        insertAirMapBCD: "insertAirMapBCD",
        insertAirLegBCD: "insertAirLegBCD",
        insertHotelBookingsBCD: "insertHotelBookingsBCD",
        insertHotelMapBCD: "insertHotelMapBCD",
        insertCarRentalsBookingsBCD: "insertCarRentalsBookingsBCD",
        insertCarRentMapBCD: "insertCarRentMapBCD",
        etaTravelerSearchAdmin: "etaTravelerSearchAdmin",
        insertTraveler:"insertTraveler",
        updateTravelerInfo:"updateTravelerInfo",
    }
    airRailService = {
        getBooking: "getBooking",
        getAirLineCodes: "getAirLineCodes",
        getTravelClassCodes: "getTravelClassCodes",
        getAirBookingAirId: "getAirBookingAirId",
        insertAirLeg: "insertAirLeg",
        insertAirMap: "insertAirMap",
        updateAirMap: "updateAirMap",
        userProcessUpdate_AirRail: "userProcessUpdate_AirRail",
        userProcessInsertCopy_AirRail: "userProcessInsertCopy_AirRail"
    }
    hotelService = {
        getBillingType: "getBillingType",
        getHotelId: "getHotelId",
        getBooking: "getBooking",
        userProcessInsert: "userProcessInsert",
        userProcessCopy: "userProcessCopy",
        userProcessUpdate: "userProcessUpdate",
        bookingMap: "bookingMap",
        updateSpecialIns: "updateSpecialIns",
        updateBCDToManual:"updateBCDToManual"
    }
    rentalCarService = {
        getBillingType: "getBillingType",
        getRentalType: "getRentalType",
        getCarRentId: "getCarRentId",
        getBooking: "getBooking",
        userProcessInsert: "userProcessInsert",
        userProcessCopy: "userProcessCopy",
        userProcessUpdate: "userProcessUpdate",
        bookingMap: "bookingMap",
        updateSpecialIns: "updateSpecialIns"
    }
    carServiceService = {
        getServiceType: "getServiceType",
        getCarCompany: "getCarCompany",
        getBooking: "getBooking",
        getCarType: "getCarType",
        getCarServiceBookingCarServiceId: "getCarServiceBookingCarServiceId",
        getCarServiceLocation: "getCarServiceLocation",
        userProcessInsert: "userProcessInsert",
        userProcessCopy: "userProcessCopy",
        userProcessUpdate: "userProcessUpdate",
        bookingMap: "bookingMap",
        updateSpecialIns: "updateSpecialIns",
        carServiceLocation: "carServiceLocation"
    }
    codingSummaryService = {
        getCodingSummary: "getCodingSummary",
        getCodingSummaryForUnique: "getCodingSummaryForUnique",
        getTravellerDetails: "getTravellerDetails",
        getTACoding: "getTACoding",
        getCodingsCount: "getCodingsCount",
        insertCoding: "insertCoding",
        updateTRCoding: "updateTRCoding",
        deleteCoding: "deleteCoding",
        insertUpdateCoding: "insertUpdateCoding"
    }
    approverService = {
        getApproverInbox: "getApproverInbox",
        getApprovalHistory: "getApprovalHistory",
        getLabelApprovers: "getLabelApprovers",
        getApproverHistory: "getApproverHistory"
    }
    bcdAgentService = {
        getWTPIncomingTAs: "getWTPIncomingTAs",
        getConfirmationPage: "getConfirmationPage",
        getAuthorizer: "getAuthorizer",
        applyConfirmation: "applyConfirmation",
        saveConfirmation: "saveConfirmation",
        getWTPHistory: "getWTPHistory"
    }
    submitterService = {
        getUnsubmittedTA: "getUnsubmittedTA",
        getTAHistory: "getTAHistory"
    }
    bcdwsService = {
        BCDSubmitTAValidation: "BCDSubmitTAValidation",
        addSortRemarkToPNR: "addSortRemarkToPNR",
        addSortBCDRemarkToPNR:"addSortBCDRemarkToPNR",
        viewSortRemarkToPNR:"viewSortRemarkToPNR"
    }
    emailService = {
        sendEmail: "sendEmail",
        sendTechSupportEmailAPI: "sendTechSupportEmailAPI"
    }
    restAPIService = {
        postRestAPI: "postRestAPI"
    }
    soapSAPService = {
        sapPOValidation: "sapPOValidation"
    }
    carVoucherService={
        createVoucher: "createVoucher",
        getCarCompanyName:"getCarCompanyName",
        getCarCompanyDetails:"getCarCompanyDetails",
        getHFMEntity:"getHFMEntity",
        getCarVoucherHistory:"getCarVoucherHistory",
        getCarVoucherDetails:"getCarVoucherDetails",
    }
    approvalDiagramService={
        getUserLabelGroups: "getUserLabelGroups",   
        getApprovalChain:"getApprovalChain",   
        getLabelAndBillingApprovers:"getLabelAndBillingApprovers",
        getSrExecutives:"getSrExecutives"  
    }
    directBillActivityService = {
        getDirectBillActivity: "getDirectBillActivity",
        getDirectBillHotelView: "getDirectBillHotelView",
        getDirectBillTACoding: "getDirectBillTACoding",
        getDirectBillPrint: "getDirectBillPrint",
        getDirectBillActivityView: "getDirectBillActivityView",
        directBillUserProcessUpdate: "directBillUserProcessUpdate",
        directBillSaveDbPrint: "directBillSaveDbPrint",
        directBillSaveInvoice: "directBillSaveInvoice",
        getDirectBillContactId:"getDirectBillContactId",
        getDirectBillContactList:"getDirectBillContactList",
        getDirectBillCountry:"getDirectBillCountry",
        getDirectBillSearchContact:"getDirectBillSearchContact",
        getDirectBillState:"getDirectBillState",
        getDirectBillContactData:"getDirectBillContactData",
        directBillDeleteContact:"directBillDeleteContact",
        directBillInsertContact:"directBillInsertContact",
        directBillUpdateContact:"directBillUpdateContact",
        checkDirectBillContactData: "checkDirectBillContactData" 
    }
    directBillAllReport = {
        //Folio
        getdirectBillSearchReport: "directBillSearchReport",
        directBillGotFolioUpdate: "directBillGotFolioUpdate",
        //Not Folio
        getdirectBillReportNotFolio: "directBillReportNotFolioSearchReport",
        directBillReportNotFolioUpdate: "DirectBillReportNotFolioUpdate",
        //Guest Arrival
        DirectBillReportGuestArrival: "GuestArrival",
        //Vendor Payment
        DirectBillReportVendorPayment: "VendorPayment",
        //Labels
        getAllLabels: "getAllLabels",
        //Expense Types
        getExpenseType: "getExpenseType",
        //Submitted Payment
        DirectBillReportsubmittedayment: "submittedPayment",
        //Submit Payment
        DirectBillReportsubmitPayment: "submitPayment",
        //Submit Payment
        DirectBillReportsubmitPaymentUpdate: "submitPaymentUpdate",
    }
    labelMaintenanceService = {
        getAllLabels:"getAllLabels",
        createUpdateLabel:"createUpdateLabel",
        getAllActiveLabels:"getAllActiveLabels"
    }
    systemMaintenanceService = {
        getAllSysMessage:"getAllSysMessage",
        getAllActiveSysMessage:"getAllActiveSysMessage",
        createUpdateDeleteSysMessage:"createUpdateDeleteSysMessage"
    }
    businessUnitMaintenanceService={
        getBusinessUnitByExpenseType:"getBusinessUnitByExpenseType",
        createUpdateBusinessUnit:"createUpdateBusinessUnit"       
    }
    carServiceMaintenanceService={
        getCarServiceMaintenanceHistory:"getCarServiceMaintenanceHistory",   
        getExpenseType:"getExpenseType", 
        inserUpdateDeleteCarServiceCode:"inserUpdateDeleteCarServiceCode",
        inserUpdateCarServiceCompany:"inserUpdateCarServiceCompany",
        getCarServiceCompany:"getCarServiceCompany",
        getUserTypeCodes:"getUserTypeCodes"   
    }
    departmentMaintenanceService={
        getDepartmentByLabel:"getDepartmentByLabel",
        createUpdateDepartment:"createUpdateDepartment"
    }
    incomingCAService={
        carServiceIncomingCAList:"carServiceIncomingCAList",
        getTa:"getTa",
        getIncomingTaDetails:"getIncomingTaDetails",
        getCarServiceApprovalHistory:"getCarServiceApprovalHistory",
        updateConfirmation:"updateConfirmation",
        getCarServiceCoding:"getCarServiceCoding",
        getCarServiceHistory:"getCarServiceHistory"
    }
    bcdMaintatenanceService={
        getRadioRoutingList:"getRadioRoutingList",
        insertUpdateRadioRouting:"insertUpdateRadioRouting",
        deleteRadioRouting:"deleteRadioRouting",
        getAirlineCodeList:"getAirlineCodeList",
        insUpdDelAirlineCode:"insUpdDelAirlineCode",
        getFilterAirlineCodesList:"getFilterAirlineCodesList",
        getBCDAirlineVendors:"getBCDAirlineVendors",
        getAirportCodeList:"getAirportCodeList",
        insUpdDelAirportCode:"insUpdDelAirportCode",
        getFilterAirportCodesList:"getFilterAirportCodesList",
        insUpdDelCarVendortCode:"insUpdDelCarVendortCode",
        getCarVendorCodesList:"getCarVendorCodesList",
        insUpdDelAirlineVendortCode:"insUpdDelAirlineVendortCode",
        getAirlineVendorCodesList:"getAirlineVendorCodesList",
        insUpdDelTravelClassCode:"insUpdDelTravelClassCode",
        getTravelClassCodesList:"getTravelClassCodesList",
        getAirlegCount:"getAirlegCount",
        insUpdDelRentalTypeCode:"insUpdDelRentalTypeCode",
        getRentalTypeCodesList:"getRentalTypeCodesList",
        getRentalTypeMaxCount:"getRentalTypeMaxCount",
        getRentalCarCount:"getRentalCarCount",
        getHotelBillCodesList:"getHotelBillCodesList",
        insUpdDelHotelBillCode:"insUpdDelHotelBillCode",
        getHotelBillCount:"getHotelBillCount",
        insUpdDelRentalBillCode:"insUpdDelRentalBillCode",
        getRentalBillCodesList:"getRentalBillCodesList",
        getRentalBillCount:"getRentalBillCount",
    }

    reportService={
        getStatusTypeCodesList:"getStatusTypeCodesList",
        getCtmReportsHfm:"getCtmReportsHfm",
        getCallLetters:"getCallLetters",
        getLabels:"getLabels",
        getDepartments:"getDepartments",
        getYears:"getYears",
        sendLabels:"sendLabels",
        getActiveLabels:"getActiveLabels"
    }

    userService={
        getUserRoleType:"getUserRoleType",
        getUserExpenseType:"getUserExpenseType",
        getUserApproverTier:"getUserApproverTier",
        getUserLabels:"getUserLabels",
        getUserCrossLabel:"getUserCrossLabel",
        getUserDetail:"getUserDetail",
        updateReEnableUserLogin:"updateReEnableUserLogin",
        updateADUserDisabled:"updateADUserDisabled",
        updateADUserServiceAccount:"updateADUserServiceAccount",
        getUserActiveRoleCount:"getUserActiveRoleCount",
        getUserSrExecApproval:"getUserSrExecApproval",
        getUserDepartment:"getUserDepartment",
        updateUserDetail:"updateUserDetail",
        insertUserDetail:"insertUserDetail",
        insertUserLabel:"insertUserLabel",
        insertUserDept:"insertUserDept",
        insertUserDH:"insertUserDH",
        insertUserExpType:"insertUserExpType",
        getUserSearch:"getUserSearch",
        getADUser:"getADUser"
    }
    voucherPlusService = {
        getUsers: "getUsers",
        getCrossLabels: "getCrossLabels",
        getVoucherPlusExpenseTypeUser: "getVoucherPlusExpenseTypeUser",
        insertUpdVoucherPlusUser:"insertUpdVoucherPlusUser",
        insertUpdVoucherPlusData:"insertUpdVoucherPlusData",
        getVoucherPlusExpTypeAccountData:"getVoucherPlusExpTypeAccountData"
    }

    approvalProcessService = {
        getApprovalProcess: "getApprovalProcess",
        getApproverTierCodesWeight: "getApproverTierCodesWeight",
        getApproverTierCodes: "getApproverTierCodes",
        insertApprovalProcess: "insertApprovalProcess",
        updateApprovalProcess: "updateApprovalProcess",
        deleteApprovalProcess: "deleteApprovalProcess"
    }

    addingBCDCodingService={
        getBCDAuthorizationRequest:"getBCDAuthorizationRequest",
        getTravellerDetailsForPNRXML:"getTravellerDetailsForPNRXML",
        getValidatePNRXML:"getValidatePNRXML"
    }

    userAuthService={
        isUserAuthenticate:"isUserAuthenticate"
    }
    contentWinnerService={
        getContentWinnerList:"getContentWinnerList"
    }
    // BEGIN
    thSelectRoleArray = ["Account Type", "Label", "Department"];
    thTravelersArray = ["Name","Resv #","Reason For Travel","Retrieve Resv Results","Accept Name","Don't re-retrieve",""]; 
    thApprovalChainList=["Department","Dept Approver","Billing","Authorizer","Sr Executive","Coding"];

    tdSelectRoleArray = {
        "recordset" : [
        {"AccountType":"Administrator","Role":"Test Label Only", "Department":"TDept Three"},
        {"AccountType":"Requestor","Role":"CENTRAL VENTURES", "Department":"Celine Joshua UMG Team"},
        {"AccountType":"Approver","Role":"CENTRAL VENTURES", "Department":"Celine Joshua UMG Team"}
        ]
    }    
    //END
    tdArray = {
        "recordset" : [
        {"Artist":"CHEMICAL BROTHERS","Title":"BORN IN THE... (STD)", "Selection":"2547275264", "UPC":"00602547275264", "Config":"CD", "Units/Set":"1", "Rls Date":"07/17/15", "Qty":"1"},
        {"Artist":"CHEMICAL BROTHERS","Title":"BORN IN THE... (STD)", "Selection":"2547275264", "UPC":"00602547275264", "Config":"CD", "Units/Set":"1", "Rls Date":"07/17/15", "Qty":"1"},
        {"Artist":"CHEMICAL BROTHERS","Title":"BORN IN THE... (STD)", "Selection":"2547275264", "UPC":"00602547275264", "Config":"CD", "Units/Set":"1", "Rls Date":"07/17/15", "Qty":"1"},
        {"Artist":"CHEMICAL BROTHERS","Title":"BORN IN THE... (STD)", "Selection":"2547275264", "UPC":"00602547275264", "Config":"CD", "Units/Set":"1", "Rls Date":"07/17/15", "Qty":"1"},
        {"Artist":"CHEMICAL BROTHERS","Title":"BORN IN THE... (STD)", "Selection":"2547275264", "UPC":"00602547275264", "Config":"CD", "Units/Set":"1", "Rls Date":"07/17/15", "Qty":"1"}
        ]
    }          
    }
